.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 1s linear;
  height: 20vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
    0%   {transform: rotate(0deg);}
    25% {transform: rotate(10deg)}
    50%  {transform: rotate(0deg);}
    75%  {transform: rotate(-10deg);}
    100% {transform: rotate(0deg);}
}
